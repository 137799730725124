<template>
    <div>
        <modal name="cancel-reservation" height="auto" :width="widthModal" class="sobrescribir" @before-close="beforeClose">
            <div class="x-button" v-on:click="closeModalEnd()" v-if="!cancelLoading">
                <i class="material-icons icons-op" style="font-size:24px !important;">close</i>
            </div>
            <div v-if="cancelEnd" style="padding-top:5%; padding-bottom: 5%;">
                <div class = "row">
                    <div class = "col-md-12" style="color:#00B5AD;font-size: 35px;">
                        <div class="center">
                            <strong><i class="material-icons">check_circle_outline</i></strong>
                        </div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col-md-12" style="font-weight: 600;">
                        <div class="center">
                            <strong>Tu reserva fue cancelada con éxito.</strong> <br> Tus millas serán devueltas a la brevedad a tu cuenta LATAM Pass.
                        </div>
                    </div>
                </div>
            </div>
            <div class="card detail-margin border-zero" style="background-color:#F5F5F5;">    
                <div class="card-body pb-0">
                    <div class = "finalsquare-b">
                        <div class = "bookingid center">
                            <h5>ID DE LA RESERVA:</h5>
                            <p>
                                <h4 class = 'idnumber'>{{ getSupplierConfirmationNumber }}</h4>
                            </p>
                            <p class="nro-operation" >
                                Nro Operación: {{ getOperationNumber }}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card detail-margin border-zero" v-if="!cancelEnd">
                <div class="card-body vehicle-resume">
                    <div class = "row">
                        <div class = "col-md-8">
                            <div style="float:left">
                                <div>
                                    <strong class = "idnumber">Su coche</strong>
                                </div>
                                <div>{{ getVehicleName() }}</div>
                            </div>
                        </div>
                        <div class = "col-md-4">
                            {{getSupplierName()}}
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "col-md-4">
                            <img :src="getVehicleImage()" style="width: 126px; height: 84px;" class="mx-auto" />
                        </div>
                        <div class = "col-md-4">
                            <div class="general-info">
                                <i class = "material-icons icons-op">person</i>
                                {{ getSeatDoors() }}
                            </div>
                            <div class="general-info">
                                {{ getTransmition() }}
                            </div>
                            <div class="general-info" v-if="hasAircon()">
                                <i class="material-icons icons-op">ac_unit</i>
                                Aire acondicionado
                            </div>
                            <div class="general-info" v-if="hasAirbag()">
                                Bolsa de Aire
                            </div>
                        </div>
                        <div class = "col-md-4 features">
                            <div class="feature" :class="getFuelPolicy() === 'Lleno/Lleno' ? 'green' : ''">
                                <div class="heading">Política de combustible</div>
                                <i class="material-icons icons-op">local_gas_station</i>
                                {{getFuelPolicy()}}
                            </div>
                            <div class="feature" :class="isUnlimitedMileage() ? 'green' : ''">
                                <div class="heading">Kilometraje</div>
                                <i class="material-icons icons-op">location_on</i>
                                {{isUnlimitedMileage() ? 'Ilimitado' : 'Limitado'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-zero" style="overflow-x:hidden;">
                <div class="card-body" style="padding:0;" v-if="!cancelEnd">
                    <div class = "row border-table" style="margin-left:0px;" v-if="booking != null">
                        <div class = "col-md-6 col-xs-12 b-table-one">
                            <div class = "float-left">DIAS TOTALES DE LA RESERVA</div>
                            <div class = "float-left positive_miles ml-4"><h5><strong>{{getDays()}} días</strong></h5></div>
                        </div>
                        <div class = "col-md-6 col-xs-12 b-table-two">
                            <div class = "float-left">COSTO ARRIENDO</div>
                            <div class = "float-left positive_miles ml-4"><h5><strong>{{getMiles()}} millas</strong></h5></div>
                        </div>
                        <div class = "col-md-6 col-xs-12 b-table-one">
                            <div>
                                <h5><strong class = "idnumber">Recogida</strong></h5>
                            </div>
                            <div>
                                <p>{{getLocationTitle('PickUp')}}</p>
                                <p><i class="material-icons icons-op">date_range</i>
                                <strong> 
                                {{ 
                                    getInfo().PickUp[0].Date[0].$.year + '/' +
                                    setZero(getInfo().PickUp[0].Date[0].$.month) + '/' +
                                    setZero(getInfo().PickUp[0].Date[0].$.day) + ', ' +
                                    setZero(getInfo().PickUp[0].Date[0].$.hour) + ':' +
                                    setZero(getInfo().PickUp[0].Date[0].$.minute) 
                                    }}
                                </strong></p>
                                <p>
                                    <i class="material-icons icons-op">location_on</i>
                                    <strong>
                                        {{getAddress('PickUp')}}
                                    </strong>
                                </p>
                                <p>
                                    <i class="material-icons icons-op">phone</i>
                                    <strong>
                                        {{getPhone('PickUp')}}
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div class = "col-md-6 col-xs-12 b-table-two">
                            <div>
                                <h5><strong class = "idnumber">Devolución</strong></h5>
                            </div>
                            <div>
                                {{getLocationTitle('DropOff')}}
                                <p><i class="material-icons icons-op">date_range</i>
                                <strong>
                                    {{ 
                                    getInfo().DropOff[0].Date[0].$.year + '/' +
                                    setZero(getInfo().DropOff[0].Date[0].$.month) + '/' +
                                    setZero(getInfo().DropOff[0].Date[0].$.day) + ', ' +
                                    setZero(getInfo().DropOff[0].Date[0].$.hour) + ':' +
                                    setZero(getInfo().DropOff[0].Date[0].$.minute) 
                                    }}
                                </strong></p>
                                <p>
                                    <i class="material-icons icons-op">location_on</i>
                                    <strong>
                                        {{getAddress('DropOff')}}
                                    </strong>
                                </p>
                                <p>
                                    <i class="material-icons icons-op">phone</i>
                                    <strong>
                                        {{getPhone('DropOff')}}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="piedelmodal" v-if="!cancelEnd">
                <div class = "row" v-if="!cancelLoading">
                    <div class = "col-md-12" v-if="cancelError">
                        <strong>Ocurrió un error al momento de cancelar la reserva. Por favor intente más tarde</strong> 
                    </div>
                    <div class = "col-md-12 caja-pie" v-if="!cancelError && !cancelEnd">
                        <div v-if="checkUserWantToCancel" class="checkbox-cancelation-two" onclick="togglearCancelacion()"><i class="material-icons">done</i></div>
                        <div v-else class="checkbox-cancelation" onclick="togglearCancelacion()"><i class="material-icons" style="visibility:hidden;">done</i></div>
                        
                        <div style="padding: 0 20px;">Confirmo que deseo cancelar mi reserva. Esta cancelación es irreversible.</div>
                
                        <button class="btn btn-secondary" :class="checkUserWantToCancel ? 'btn-cancelar' : 'btn-cancelar-disabled'" :disabled="!checkUserWantToCancel" style="vertical-align: middle;" v-on:click="cancelBooking()"> <i class="material-icons" >cancel</i> Cancelar reserva</button>
                    </div>
                    <div class = "col-md-12 caja-pie" v-if="!cancelError && cancelEnd">

                            <div style="padding: 0 20px;"><h5>Reserva Cancelada con Éxito</h5></div>
                            <button class="btn btn-secondary btn-cancelar" style="vertical-align: middle;" v-on:click="closeModalEnd()"> <i class="material-icons" >cancel</i> Cerrar</button>
                    </div>
                    
                </div>
                <div class = "row" v-else>
                    <div class = "col-md-12">
                            <h5>Cargando...</h5>
                    </div>
                </div>
                
            </div>
        </modal>
    </div>

</template>

<script>

window.togglearCancelacion = null;

export default {
    name : 'cancelation-modal',
    props: ['booking'],
    data() {
        return {
            checkUserWantToCancel : false,
            widthModal: '40%',
            cancelEnd : false,
            cancelLoading : false,
            cancelError : false
        }
    },
    created(){
        window.togglearCancelacion = () => {
            this.checkUserWantToCancel = !this.checkUserWantToCancel;
        };
    },
    mounted(){
        this.$emit('view-modal-cancel', () => {
            this.showModalCancel()
        });
    },
    computed : { 
        getOperationNumber(){
            try {
                let booking = this.getInfo();
                return booking.$.id;
            } catch (e){
                return 'Reserva Cancelada';
            }
        },
        getSupplierConfirmationNumber(){
            try {
                let booking = this.getInfo();
                return booking.Supplier[0].$.confirmationNumber;
            } catch (e){
                return 'Reserva Cancelada';
            }
        },
    },
    methods : {
        closeModalEnd(){
            this.$modal.hide('cancel-reservation');
        },
        getInfo(){
            return JSON.parse(this.booking.booking_info);
        },
        getVehicleName() {
            try {
                return this.getInfo().Vehicle[0].Name[0];
            } catch (e){
                return '';
            }
        },
        getVehicleImage(){
            try {
                return this.getInfo().Vehicle[0].ImageURL[0];
            } catch (e){
                return '';
            }
        },
        getSeatDoors(){
            return this.getSeats() + ' Plazas | ' + this.getDoors() + ' Puertas';
        },
        getDoors(){
            try {
                return this.getInfo().Vehicle[0].$.doors;
            } catch (e){
                return '';
            }
        },
        getSupplierName(){
            try {
                return this.getInfo().Supplier[0]._;
            } catch (e){
                return '';
            }
        },
         getTransmition(){
            try {
                return this.getInfo().Vehicle[0].$.automatic == 'Manual'
                ? 'Transmisión Manual' : 'Transmisión Automática';
            } catch (e){
                return '';
            }
        },
        hasAircon(){
            try {
                return this.getInfo().Vehicle[0].$.aircon == 'Yes';
            }catch(e){
                return false;
            }
        },
        hasAirbag(){
            try {
                return !this.getInfo().Vehicle[0].$.airbag == 'false';
            }catch(e){
                return false;
            }
        },
        getFuelPolicy(){
            try {
                return this.fuelPolicy[this.getInfo().Vehicle[0].$.fuelPolicy];
            } catch (e){
                return '';
            }
        },
        isUnlimitedMileage(){
            try {
                return this.getInfo().Vehicle[0].$.unlimitedMileage == 'true';
            }catch(e){
                return false;
            }
        },
        getSeats(){
            try {
                return this.getInfo().Vehicle[0].$.seats;
            } catch (e){
                return 0;
            }
        },
        getLocationTitle(type){
            return this.getInfo()[type][0].Location[0]._;
        },
        setZero(data){
            return parseInt(data) > 9 ? data : '0' + data;
        },
        getLocation(type){
            return this.getInfo()[type][0].Location[0].$.city + ', ' + this.getInfo()[type][0].Location[0].$.country;
        },
        getAddress(type){
            return this.getInfo()[type][0].Location[0].$.address;
        },
        getPhone(type){
            return this.getInfo()[type][0].Location[0].$.telephone;
        },
        getDays(){
            try {
                let pick = 
                this.getInfo().PickUp[0].Date[0].$.year + '-' +
                this.getInfo().PickUp[0].Date[0].$.month + '-' +
                this.getInfo().PickUp[0].Date[0].$.day + '-';

                let drop = 
                this.getInfo().DropOff[0].Date[0].$.year + '-' +
                this.getInfo().DropOff[0].Date[0].$.month + '-' +
                this.getInfo().DropOff[0].Date[0].$.day + '-';

                let days = moment(drop, 'YYYY-MM-DD').diff(moment(pick, 'YYYY-MM-DD'), 'days');

                return days == 0 ? 1 : days;

            } catch (e) {
                return 0;
            }
        },
        getMiles(){
            try {
                return this.booking.redeem_amt_discount;
            } catch(e) {
                return 0;
            }
        },
        showModalCancel(){
            this.checkUserWantToCancel = false;
            this.cancelError = false;
            this.cancelEnd = false;
            this.widthModal = '40%';
            this.$modal.show('cancel-reservation');
            
        },
        cancelBooking(){
            
            this.cancelLoading = true;
            this.cancelError = false;

            const params = {
                bookingid: this.booking.rentalcars_id
            };

            axios.get('cancel-booking', {params}).then(response => {
                this.cancelLoading = false;
                this.cancelEnd = true;
                this.cancelFinished = true;
                this.widthModal = '30%';
                this.$emit('finish-cancelation', this.booking);
            }).catch(error => {
                this.cancelLoading = false;
                this.cancelError = true;
                console.log(error);
            });
        },
        beforeClose(event, paramdos){
            if (this.cancelLoading){
                this.$nextTick(() => {
                     this.$modal.show('cancel-reservation');
                });
            }
        }
    }
}
</script>

<style scoped>

    .cancel-button {
        color: #E8114B;
    }

    .cancel-button > i {
        font-size: 12px;
        vertical-align: middle;
    }

    .center {
         text-align: center;
    }

    .vehicle-resume {
        padding-left:13%; 
        padding-right:13%;
        font-size:small;
        border: 0;
    }

    .border-zero {
        border: 0;
    }

    .b-table-one {
        border-top: 1px solid #CCCCCC; 
        border-right: 1px solid #CCCCCC;
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .b-table-two {
        border-top: 1px solid #CCCCCC; 
        padding-top: 13px;
        padding-bottom: 10px;
    }

    .piedelmodal {
        background-color: #E8114B;
        padding: 16px;
        margin: 0px;
        color: #fff;
    }

    .positive_miles {
        color: #00B5AD;
    }

    .checkbox-cancelation {
        background-color: #E8114B;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        margin-left:2%;
        cursor: pointer;
    }

    .caja-pie {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;

    }

    .btn-cancelar {
        white-space: nowrap;
        display: flex;
        align-items: center;
        background: #FFFFFF;;
        color: #E8114B;
    }

    .btn-cancelar-disabled {
        white-space: nowrap;
        display: flex;
        align-items: center;
        background: #FFFFFF;;
        color: #858585;
    }

    .checkbox-cancelation-two {
        background-color: #FFFFFF !important;
        border-radius: 4px;
        color: #E8114B;
        border: 1px solid #E8114B !important;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        margin-left:2%;
        cursor: pointer;
    }

    .x-button {
        border-radius: 50%;
        background-color: #fff;
        display: block;
        z-index: 999;
        position: absolute;
        right: -50px;
        top: -50px;
        padding: 7px 7px 0px 7px;
        cursor: pointer;
    }

</style>

<style>
    
    .sobrescribir > .v--modal-background-click > .v--modal-box {
        overflow: visible !important;
    }
</style>