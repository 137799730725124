/* globals Vue */
/* eslint-disable no-unused-vars */
import SearchBox from "@/components/SearchBox";
import Navbar from "@/components/NavbarNew";

import SearchResults from "@/pages/search/Results";
import BookingExtras from "@/pages/booking/Extras";
import ExtraSideBar from "@/components/ExtraSideBar";

import Terms from "@/pages/booking/Terms";

import Detail from "@/pages/booking/Detail";

import MyReservations from "@/pages/booking/MyReservations";

import CancelationModal from "@/components/CancelationModal";

import InformationModal from "@/components/modal/information";

import store from "@/store";

import VModal from "vue-js-modal";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Vue.use(VModal);

Sentry.init({
  dsn: "https://ff7564c707334905919b75fed30b580d@sentry.io/1496307",
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});

window.buyMilesUrl =
  "https://www.latam.com/es_cl/latam-pass/como-acumular-millas/volando/en-latam-airlines/";

//import Vuex from 'vuex';

//Vue.use(Vuex);

const app = new Vue({
  el: "#app",
  store,
  components: {
    Navbar,
    SearchBox,
    SearchResults,
    BookingExtras,
    Terms,
    Detail,
    ExtraSideBar,
    MyReservations,
    CancelationModal,
    InformationModal,
  },
});
