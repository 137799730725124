<template>
    <div class="minicard-component" :class="{ active }" @click.prevent="handleClickCard">
        <h4>Coches<br>{{ title }}</h4>
        <img :src="match.Vehicle[0].ImageURL[0]" class="mx-auto car-photo">
        
        <div class="total-price">
            Desde<br>
            {{ numeral(lowestPrice).format('0,0.[00]')  }} Millas
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            match: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                required: true
            },
            lowestPrice: {
                type: Number,
                required: true
            }
        },
        methods: {
            handleClickCard() {
                this.$emit('card-clicked', this.title);
            },
            numeral: numeral
        }
    };
</script>