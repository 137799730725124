<template>
    <div class="result-card-component mt-3">
        <div class="row">
            <div class = "col-md-12">
                <div class = "row heading-box">
                    <div class = "col-6">
                        <div class = "title-ext">
                            <strong>Vehículo {{match.cardAttributes.mainCategory}}</strong>
                        </div>
                        <div class = "subtitle-ext">
                            {{match.Vehicle[0].Name[0]}} o similar
                        </div>
                    </div>
                    <div class = "col-6">
                        <div class="image-container align-self-end">
                            <img :src="match.Supplier[0].$.small_logo" class="mx-auto">
                        </div>
                    </div>
                </div>
                <div class = "row card-elements">
                    <div class = "col-6">
                        <div class="image-container">
                            <img :src="match.Vehicle[0].ImageURL[0]" style="width:100%; height: 100%;" class="mx-auto">
                        </div>
                        <div class="general-info">
                            <i class="material-icons">person</i> <strong>{{match.Vehicle[0].$.seats}}</strong> personas
                        </div>
                        <div class="general-info">
                            <i class="material-icons">work</i> <strong>{{match.Vehicle[0].$.bigSuitcase }}</strong> maletas
                        </div>
                    </div>
                    <div class = "col-6" style="text-align:left">
                        <div class="row no-gutters">
                            <div class="col-xs-12" >
                                <div class="feature">
                                    Transmisión {{match.cardAttributes.transmission}}
                                </div>
                                <div class="feature" v-if="hasAircon">
                                    <i class="material-icons">ac_unit</i>
                                    Aire acondicionado
                                </div>
                                <div class="feature">
                                    <i class="material-icons">local_gas_station</i>
                                    Kilometros por litro
                                </div>
                                <div class="feature">
                                    <i class="material-icons">directions_car</i>
                                    {{match.cardAttributes.doors}} puertas
                                </div>
                                <div class="feature" :class="match.cardAttributes.fuelPolicy === 'Lleno/Lleno' ? 'green' : ''">
                                    <div class="heading">Política de combustible</div>
                                    <i class="material-icons">local_gas_station</i>
                                    {{match.cardAttributes.fuelPolicy}}
                                </div>
                                <div class="feature" :class="match.cardAttributes.mileage === 'Ilimitado' ? 'green' : ''">
                                    <div class="heading">Kilometraje</div>
                                    <i class="material-icons">location_on</i>
                                    {{match.cardAttributes.mileage}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class = "row" v-if="existDays()">
                    <div class="col-md-12">
                        <div class="pricing-rent-info">
                            
                            <div class="price-tag">
                                <div class = "row">
                                    <div class = "col-6">
                                        <div class="total-price card-price">
                                            <div class="title">Precio por {{ days }} días</div>
                                            {{numeral(match.cardAttributes.mileTotalPrice).format('0,0.[00]')}}
                                            <br>
                                            Millas Latam
                                        </div>
                                        <div class="price-by-day card-price">
                                            {{numeral(match.cardAttributes.mileDayPrice).format('0,0.[00]')}}/día
                                        </div>
                                    </div>
                                    <div class = "col-6">
                                        <a v-if="showBookButton" :href="bookingUrl" class="buy-button_mini" target="_blank">Reservar</a>
                                    </div>
                                </div>
                            </div>

                            <div class="featured-badge">
                                {{match.cardAttributes.offerType}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            
</template>

<script>
    import resize from '@/../resize.js'

    export default {
        props: {
            match: {
                type: Object,
                required: true
            },
            days: {
                type: Number,
                required: false
            },
            showBookButton: {
                type: Boolean,
                default: true
            },
            bookingUrl: {
                type: String,
                required: false
            }
        },
        created() {
            this.prepare();
        },
        computed: {
            hasAircon() {
                return this.match.cardAttributes.aircon;
            }
        },
        destroyed: resize.prepare,
        data(){
            return {
                window: resize.data()
            }
        },
        methods:{
            prepare: resize.prepare,
            handleResize: resize.handleResize,
            isLandscape: resize.isLandscape,
            numeral: numeral,
            existDays(){
                return typeof(this.days) != 'undefined';
            }
        }
    };
</script>

<style scoped lang="scss">

    .title-ext {
        font-weight: 800;
        font-size: 4vw;
        

    }

    .subtitle-ext {
        font-size: 3.5vw;
    }

    .heading-box {
        margin-top:5%;
        padding-left:5%;
        padding-right:5%;
    }

    .card-elements {
        font-size:3.5vw;
        padding-left:5%;
        padding-right:5%;
    }

    .card-price {
        font-size: 4vw !important;
    }

    .buy-button_mini{
        color: #fff;
        font-size: 4vw;
        font-weight: bold;
        background-color: #1B0088;
        padding: 10% 30%;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
    }
</style>
