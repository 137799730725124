<template>
    <div>
        <div class="container-fluid header-blue">
            <div class="container navbar-pos">
                <div class="nav-element" style="display: flex;align-items: center;">LATAM Pass</div>
                <div>
                    <div class="nav-butttons selected">
                        Canjear millas
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid header-white">
            <div class="container navbar-pos">
                <div class="logos">

                    <a href="/"><img class="latam-logo" src="../../../images/logos/logo_latam_car.png"></a>
                    <!--
                    <img src="../../../images/logos/Carandkey.svg" class = "car-logo item-logos">
                    <div class = "intermedio item-logos"></div>
                    <img src="../../../images/logos/Preferential_Positive_RGB_LATAM_Pass-01.png" class = "latam-logo item-logos">
                    -->
                </div>
                <div class="dash">
                    <section v-if="guest">
                        <div class="row d-flex" style="width:450px;justify-content: flex-end;">
                            <div class="col-auto">
                                <a href="/my-reservations" class="dash-link">Mis Reservas</a>
                            </div>
                            <div class="col-auto">
                                <a class="btn btn-login login" href="/login">Iniciar Sesión</a>
                            </div>
                        </div>
                    </section>
                    <section v-else>
                        <div class="row d-flex" style="width:450px;margin-top:-8px;">
                            <div class="col-auto">
                                <img src="../../../images/logos/user.svg" class="user-logo">
                            </div>
                            <div class="col-md-9">
                                <div class="row d-flex dash">
                                    <div class="col-auto">
                                        Hola {{ lowerUserName }}
                                    </div>
                                    <div class="col-auto">
                                        <span class="badge custom-lab"> {{ milesBalance }} millas</span>
                                    </div>
                                </div>
                                <div class="row d-flex dash-item">
                                    <div class="col-auto">
                                        <a href="/my-reservations" class="dash-link">Mis Reservas</a>
                                    </div>
                                    <div class="separator"></div>
                                    <div class="col-auto">
                                        <a href="/logout" class="dash-link">
                                            Cerrar Sesión
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        user: {
            type: Object,
            default: null
        },
        userBalance: {
            type: String,
            required: true
        }
    },
    computed: {
        lowerUserName() {
            if (!this.guest) {
                return this.user.name.toLowerCase();
            }
        },
        guest() {
            return this.user === null;
        },
        milesBalance() {
            return this.$store.state.milesBalance
        },
    },
    mounted() {
        console.log(this.getMilesBalance);
        if (this.milesBalance == 'nothing') {
            this.$store.commit('setMilesBalance', this.userBalance);
        }
    }
}
</script>

<style scoped>
.header-blue {
    background-color: #150067;
    height: 38px;
    color: #ffffff;
}

.header-white {
    height: 80px;
    background-color: #ffffff;
    color: #10004F;
    padding-top: 30px;
}

.nav-element {
    padding-top: 5px;
    font-weight: bold;
}

.nav-butttons {
    margin: 0px;
    padding-top: 5px;
    padding-left: 3px;
    padding-right: 3px;
}

.selected {
    background-color: #FFFFFF;
    color: #10004F;
    padding-left: 12px;
    padding-right: 12px;
    height: 100%;
    display: flex;
    align-items: center;
}

.login {
    background-color: #E9124C;
    border-radius: 0;
    color: #FFFFFF;
    padding-top: 0;
    padding-bottom: 0;
}

.logos {
    width: 60%;
}

.item-logos {
    display: inline-block;
    margin-right: 10px;
}

.latam-logo {
    margin-top: -25px;
    margin-left: -17px;
    height: 60px;
    width: 200px;
}

.car-logo {
    height: 40px;
    width: 45px;
    margin-top: -32px;
}

.intermedio {
    height: 40px;
    width: 2px;
    background-color: #1B0088;
    margin-top: -7px;
}

.dash {
    font-size: 0.9em;
    color: #333333;
    justify-content: flex-start;
}

.dash-item {
    text-decoration: underline;
}

.dash-link:link {
    color: #333333 !important;
}

.dash-link:visited {
    color: #333333 !important;
}

.user-logo {
    height: 35px;
    width: 35px;
}

.custom-lab {
    background-color: #EA164F;
    color: #FFFFFF;
    padding-top: 6px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    border-radius: 10px;
}

.separator {
    border-left: 1px #333333 solid;
    height: 15px;
    margin-top: 4px;
}
</style>