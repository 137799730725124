<template>
    <div class="card terminos" v-if="existTerms()">
        <div class="card-body">
            <button class="boton-terminos azulado" v-on:click="showModal()">Ver los términos de alquiler</button>
        </div>
        <modal name="rental-terms" height="auto">
             <div class="card">
                 <div class="card-header azulado">
                     Términos del alquiler
                 </div>
                 <div class="card-body">
                    <div class="">
                        
                    </div>
                 </div>
             </div>
        </modal>
    </div>
</template>

<style>
    .terminos {
        margin-top: 20px;
    }

    .boton-terminos {
        box-sizing: border-box;	
        border: 1px solid #CCCCCC;	
        border-radius: 4px;	
        background-color: #FFFFFF;	
        box-shadow: 0 2px 4px 0 rgba(186,186,186,0.5);
        	
        text-align: center;
        padding-top:15px;
        padding-bottom:15px;
        cursor:pointer;
    }

    .azulado {
        color: #1B0088;	
        font-family: "Latam Sans";	
        font-size: 18px;	
        font-weight: bold;	
        line-height: 22px;
    }
</style>

<script>

import { mapGetters } from 'vuex';

export default {
    computed:{
        rentalTerms(){
            return this.$store.state.rentalTerms;
        }
    },
    methods:{
        existTerms(){
            return this.rentalTerms.length > 0;
        },
        showModal(){
            this.$modal.show('rental-terms');
        }
    }
}
</script>
