<template>
    <div class="voucher-container" id="voucher">
        <div style="height:100%" class="">
            <div style="margin-left:0; margin-right:0;  background-color:#f3f2f6;"
                class="row  flex-column-reverse flex-md-row">
                <div class="col-12 col-md-8 first-column">
                <div class="m-4 m-lg-5">
                    <div class="mt-5  ">
                        <div class="h4 indigo-normal font-italic">
                            Información de la reserva
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col">
                                    <p class="h5">Recogida</p>
                                </div>
                                <div class="col">
                                    <p class="h5 text-right indigo-dark font-weight-bold"> <strong>
                                            {{
                                                setZero(bookinfo.PickUp[0].Date[0].$.day) + '/' +
                                                setZero(bookinfo.PickUp[0].Date[0].$.month) + '/' +
                                                bookinfo.PickUp[0].Date[0].$.year + ', ' +
                                                setZero(bookinfo.PickUp[0].Date[0].$.hour) + ':' +
                                                setZero(bookinfo.PickUp[0].Date[0].$.minute)
                                            }}
                                        </strong></p>
                                    <p class="h5 text-right indigo-dark font-weight-bold">{{ getLocationTitle('PickUp') }}
                                    </p>
                                    <p class="h6 text-right">{{ getPickUpLocationAddress() }}</p>
                                </div>
                            </div>
                            <el-divider></el-divider>
                            <div class="row">
                                <div class="col">
                                    <p class="h5">Devolución</p>
                                </div>
                                <div class="col">
                                    <p class="h5 text-right indigo-dark font-weight-bold">{{
                                        setZero(bookinfo.DropOff[0].Date[0].$.day) + '/' +
                                        setZero(bookinfo.DropOff[0].Date[0].$.month) + '/' +
                                        bookinfo.DropOff[0].Date[0].$.year + ', ' +
                                        setZero(bookinfo.DropOff[0].Date[0].$.hour) + ':' +
                                        setZero(bookinfo.DropOff[0].Date[0].$.minute)
                                    }}</p>
                                    <p class="h5 text-right indigo-dark font-weight-bold">{{ getLocationTitle('DropOff') }}
                                    </p>
                                    <p class="h6 text-right">{{ getDropOffLocationAddress() }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5" >
                        <div class="h4 indigo-normal font-italic">
                            Información importante
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col d-flex  flex-column justify-content-between ">
                                    <div class=" d-flex justify-content-between">
                                        <p class="h5">Política de combustible:</p>
                                        <p class="h5 text-right indigo-dark font-weight-bold">{{ getFuelPolicy() }}</p>
                                    </div>
                                    <div class=" d-flex justify-content-between">
                                        <p class="h5">Kilometraje:</p>
                                        <p class="h5 text-right indigo-dark font-weight-bold"> Ilimitado
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5">
                        <div class="h4 indigo-normal font-italic">
                            Depósito/franquicia
                        </div>
                        <div class="box">
                            <div v-html="getDepositisFees()"></div>
                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5">
                        <div class="h4 indigo-normal font-italic">
                            Coche
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col ">
                                    <!--  <p class="h5 font-weight-bold">Económico</p> -->

                                    <p class="indigo-dark"><i style="color:green;" class="el-icon-circle-check mr-1"></i> {{
                                        getSeatDoors() }} </p>
                                    <p class="indigo-dark" v-if="hasAircon()"><i style="color:green;"
                                            class="el-icon-circle-check mr-1"></i> Aire
                                        acondicionado</p>
                                    <p class="indigo-dark"><i style="color:green;" class="el-icon-circle-check mr-1"></i> {{
                                        getTransmition() }}</p>
                                    <p class="indigo-dark" v-if="hasAirbag()"><i style="color:green;"
                                            class="el-icon-circle-check mr-1"></i>
                                        Bolsa de Aire</p>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5"  v-if="existInsurance">
                        <div class="h4 indigo-normal font-italic">
                            Cobertura
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col">
                                    <div style="background-color: #E7F4F3; border-radius:8px; margin-bottom:16px;">
                                        <div style="padding:16px; border-radius:8px;" class="row">
                                            <div class="col-2 d-flex justify-content-center  align-items-center">
                                                <img src="/images/secure.svg" style="height:54px; width:54px;" alt="">
                                            </div>
                                            <div class="col-10">
                                                <p style="margin-bottom:8px;">No te preocupes, {{ getFirstName() }}, estás
                                                    cubierto.</p>
                                                <p class="h5 font-weight-bold indigo-dark">Cobertura
                                                    Premium</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="h5">Cubre daños al vehículo</p>
                                    <p class="indigo-dark">Carrocería, neumáticos, ventanas,motor...todo menos el interior
                                        de
                                        vehículo.</p>

                                    <p class="h5">Reembolso de gastos adicionales</p>
                                    <p class="indigo-dark">Incluye tasas por remolque e inmovilización, gastos
                                        administrativos y
                                        mucho más.</p>

                                    <p class="h5">No te compliques</p>
                                    <p class="indigo-dark">Si te cobran por de reparaciones, se te devolverá.</p>

                                    <el-divider></el-divider>
                                    <p class="indigo-dark">*Son aplicables Términos y Condiciones y exclusiones estándar.
                                    </p>
                                    <a target="_blank" :href="insuranceUrl" class="font-weight-bold"
                                        style="color:#4658DF;">Ver Términos de la
                                        Póliza</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- v-if="getSupplierName()" -->
                    <div v-if="getSupplierName()" class="mt-3 mt-lg-5" >
                        <div class="h4 indigo-normal font-italic">
                            Compañía de alquiler
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col d-flex  flex-column justify-content-between ">
                                    <div v-if="getSupplierName()" class=" d-flex justify-content-between">
                                        <p class="h5">Nombre:</p>
                                        <p class="h5 text-right indigo-dark font-weight-bold">{{ getSupplierName() }}</p>
                                    </div>

                                    <div class=" d-flex justify-content-between">
                                        <p class="h5">Teléfono(s):</p>
                                        <p class="h5 text-right indigo-dark font-weight-bold">{{ getPickUpSupplierPhone() }}
                                            {{ getDropOffSupplierPhone() }}
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div v-if="getPickUpInstructions()">
                                <el-divider></el-divider>
                                <div v-if="getPickUpInstructions()">
                                    <p class="h5">Instrucciones para la recogida:</p>
                                    <div class="indigo-dark">{{ getPickUpInstructions() }}</div>
                                </div>

                                <div v-if="getDropOffInstructions()">
                                    <p class="h5">Instrucciones para la Devolución:</p>
                                    <div class="indigo-dark">{{ getDropOffInstructions() }}</div>
                                </div>
                            </div>
                            <!-- 
                            <el-divider></el-divider>
                            <div style="padding:16px; background-color: #E7F4F3; border-radius:8px; margin-bottom:16px;">
                                <p class="h5">Para el uso del personal de la oficina de {{ getSupplierName() }}:</p>
                                <div class="row">
                                    <div class="col-4 d-flex  flex-column justify-content-between ">
                                        <p>Datos del conductor principal:</p>
                                        <p>Número de vuelo</p>
                                    </div>
                                    <div class="col d-flex  flex-column justify-content-between">
                                        <p class=" text-right indigo-dark font-weight-bold">{{ getDriveName() }}</p>
                                        <p class=" text-right indigo-dark font-weight-bold">JIGSAW</p>

                                    </div>
                                </div>
                            </div> -->


                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5">
                        <div class="requirements h4 indigo-normal font-italic">
                            ¿Qué necesita llevar para retirar el vehículo?
                        </div>
                        <div class="box">
                            <div class="row">
                                <div class="col">
                                    <p class="h5">Permiso de conducir</p>
                                    <p class="indigo-dark">Tanto el conductor principal como los conductores adicionales
                                        deberán
                                        presentar un permiso de conducir válido a su nombre.
                                        Es responsabilidad de cada conductor averiguar la documentación que necesita antes
                                        de
                                        conducir en otro país. Por ejemplo, es posible que necesite un visado o un permiso
                                        internacional de conducción, además del permiso de conducir.
                                        Cada conductor tendrá que presentar un permiso de conducir válido. Si no está
                                        escrito en
                                        caracteres latinos, también tendrá que presentar un permiso internacional de
                                        conducir o
                                        una traducción certificada. Se recomienda a cualquier conductor con un permiso de
                                        conducir de fuera de Europa que lleve también un permiso internacional de conducir.
                                    </p>
                                    <el-divider></el-divider>

                                    <p class="h5">Tarjetas de crédito aceptadas</p>
                                    <p class="indigo-dark">
                                        El conductor principal deberá presentar una tarjeta de crédito a su
                                        nombre para retirar el vehículo. La tarjeta deberá disponer de fondos suficientes
                                        para
                                        cubrir el depósito/franquicia que le bloquearán hasta que finalice el alquiler.
                                        Deberá
                                        utilizar la misma tarjeta para pagar los impuestos locales, los extras solicitados
                                        en su
                                        reserva o cualquier otro servicio adicional que decida contratar localmente.
                                    </p>
                                    <p class="font-weight-bold indigo-dark">El conductor principal deberá presentar este
                                        vale de
                                        confirmación cuando vaya a
                                        retirar
                                        el vehículo.</p>

                                    <p class="indigo-dark">Si necesitas más información, consulta los términos y
                                        condiciones.
                                    </p>

                                    <el-divider></el-divider>

                                    <p class="h5">Identificación</p>
                                    <div>
                                        <p class="indigo-dark">En el mostrador, deberá proporcionar:</p>
                                        <p class="indigo-dark">Permiso de conducir válido de cada conductor</p>
                                        <p class="indigo-dark">Su vale de confirmación</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="getHelpText()" class="mt-3 mt-lg-5" >
                        <div class="h4 indigo-normal font-italic">
                            Ayuda
                        </div>
                        <div class="box">
                            <div v-html="getHelpText()">
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 mt-lg-5" >
                        <div class="h4 indigo-normal font-italic mb-3">
                            Términos y Condiciones
                        </div>
                        <div>
                            <div class="row">
                                <div class="col">
                                    <div class="terms" v-html="getTerms()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                </div>
                <div  class="col-12 col-md-4 second-column" style="background:white;">
                    <div class="my-5 mx-4">
                        <div class="h3 indigo-normal font-italic mb-4">
                            Resumen
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-column mb-5 ">
                            <img style="width:100%; max-width:300px;" :src="getVehicleImage()" alt="">
                            <p class="h4 indigo-dark mt-3">{{ getVehicleName() }}</p>
                        </div>

                        <div>
                            <p class="indigo-dark font-weight-bold"> Su coche</p>
                            <div
                                style="padding:16px; box-shadow: 0px 2px 8px rgba(48, 48, 48, 0.12);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                border-top-left-radius: 8px 8px; border-top-right-radius: 8px 8px; background-color:#1B0088;">
                                <div style="background-color:#1B0088">
                                    <div class="d-flex justify-content-between">
                                        <p style="font-size:18px" class="text-white font-weight-bold mb-0 "> Nº de reserva (referencia)
                                        </p>
                                        <p class="text-white font-weight-bold mb-0 h4 "> {{ getOperationNumber()
                                        }}</p>
                                    </div>
                                    <el-divider style="background-color:#E8114B"></el-divider>
                                    <div class="d-flex justify-content-between">
                                        <div style="font-size:18px"
                                            class="text-white font-weight-bold mb-0 d-flex justify-content-center align-items-center">
                                            <img src="/images/car.svg" alt="">
                                        </div>
                                        <div class="text-white font-weight-bold mb-0 h4">
                                            <p style="font-size:14px;" class="mb-0 text-white text-right">Costo arriendo
                                            </p>
                                            <p class="mb-0 h5 text-white text-right"><span class="font-weight-bold">{{ getMiles
                                            }}</span>
                                                millas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="padding:16px; box-shadow: 0px 2px 8px rgba(48, 48, 48, 0.12); border-bottom-left-radius: 8px 8px; border-bottom-right-radius: 8px 8px; background-color:white;">
                                <div class="d-flex justify-content-between">
                                    <div class="indigo-bright">Nº de Operación</div>
                                    <div class="h5 mb-0">{{ getSupplierConfirmationNumber() }}</div>
                                </div>
                                <el-divider></el-divider>
                                <div class="d-flex justify-content-between">
                                    <div class="indigo-bright">Certificado de premio</div>
                                    <div class="h5 mb-0">{{ getCertificateNumber() }}</div>
                                </div>
                                <el-divider></el-divider>
                                <div class="d-flex justify-content-between">
                                    <div class="indigo-bright">Días totales de la reserva</div>
                                    <div class="h5 mb-0">{{ getDays() }} </div>
                                </div>
                            </div>

                            <div class="mt-5">
                                <p class="indigo-normal mb-2">Información del Conductor</p>
                                <div class="box">
                                    <span style="color:#303030">Nombre</span>
                                    <p class="indigo-dark h4">{{ getDriveName() }}</p>

                                    <span style="color:#303030" class="indigo-dark">Socio Nro</span>
                                    <p class="indigo-dark h4">{{ rut }}</p>


                                </div>
                            </div>
                            <div data-html2canvas-ignore class="d-flex justify-content-center flex-column mt-5">
                                <button type="button" class="buy-button" @click="downloadVoucher()">
                                    <i class="material-icons" style="float:left;">download</i>
                                    <span>Descargar Voucher</span>
                                </button>
                                <div v-if="auth && isNotCanceled && getIfCancellable && owner" @click="showModalCancel"
                                    style="cursor:pointer;" class="text-center indigo-bright h5 mt-3">Cancelar reserva</div>

                            </div>

                            <div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <cancelation-modal :booking="transaction" @view-modal-cancel="bindCancelAction" ref="cancelation"
            @finish-cancelation="finishCancelation"></cancelation-modal>

    </div>
</template>


<script>
import CancelationModal from '@/components/CancelationModal';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import _ from 'lodash';
export default {
    props: ['auth', 'bookinfo', 'days', 'miles', 'rut', 'terms', 'insuranceUrl', 'transaction', 'owner'],
    components: {
        CancelationModal
    },
    created() {
        /* check if the url contains the var pdf = true i dont use $route */
        if (window.location.href.indexOf('pdf=true') > -1) {
            console.log("has pdf")
            setTimeout(() => {
                this.downloadVoucher();
            }, 2000);
        }
    },
    data() {
        return {
            fuelPolicy: [
                'Indefinida',
                'Lleno/Lleno',
                'Prepago, sin reembolso',
                'Prepago, con reembolso',
                'Tanque Libre'
            ],
            isNotPrinted: true,
            modelTerms: {

            },
            cancelAction: null,
            finishedCancel: false
        }
    },
    computed: {
        getMiles() {
            return this.miles.toLocaleString();
        },
        existInsurance() {
            return (typeof (this.insuranceUrl) != 'undefined' &&
                this.insuranceUrl != null &&
                this.insuranceUrl.trim() != "");
        },
        isNotCanceled() {
            return this.transaction.canceled_at === null && !this.finishedCancel;
        },
        getIfCancellable() {
            if (this.transaction.canceled_at != null) {
                return false;
            }

            //    let book = JSON.parse(this.transaction.booking_info);
            let init = this.transaction.pick.split('-');
            let pick = init[0] + '-' + init[1] + '-' + init[2] + ' ' + this.bookinfo.PickUp[0].Date[0].$.hour + ':' + this.bookinfo.PickUp[0].Date[0].$.minute;

            let difference = moment(pick, 'YYYY-MM-DD HH:mm').diff(moment.utc(), 'minutes');

            return difference >= (48 * 60);
        }
    },
    methods: {
        getHelpText() {
            let supplierName = this.getSupplierName();
            if (supplierName) {
                let supplierPhone1 = this.getPickUpSupplierPhone();
                let supplierPhone2 = this.getDropOffSupplierPhone();
                let text = `<p class="indigo-dark mb-0">Si tiene algún problema con el vehículo de alquiler,
                 póngase en contacto con
                ${supplierName} a través del número ${supplierPhone1} ${supplierPhone2}.</p>
                <p class="indigo-dark mb-0">Si desea realizar cualquier otra consulta durante su alquiler, llámenos al +44 161 602 5431.</p>`
                return text;
            }
            return '';
        },
        async downloadVoucher() {
            try {
                this.isNotPrinted = false;
                let pdfContainer = document.getElementById('voucher');
                let element = document.getElementById('app');
                pdfContainer.classList.add('pdf-print')
                /* check if the divice is apple */
                if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
                 // the device is an Apple device so we need to set the height of the div
                    let height = element.offsetHeight;
                    if (height > 5000) {
                        element.style.height = '5200px';
                    }
                } 

                let opt = {
                    margin: 0,
                    filename: 'reserva.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { backgroundColor:'#f3f2f6', scale:2,  proxy: '/proxy', windowWidth: '755', width:'792'},
                    pagebreak: { mode: ['css', 'legacy'] },
                    jsPDF: { unit: 'mm', format: 'a4' }
                };
                await html2pdf().set(opt).from(element).save();
                pdfContainer.classList.remove("pdf-print"); 
                this.isNotPrinted = true;
              
            } catch (e) {
                pdfContainer.classList.remove("pdf-print"); 
                console.error('Error al imprimir', e);
            }
        },
        bindCancelAction(action) {
            this.cancelAction = action;
        },
        showModalCancel() {
            this.cancelAction();
        },
        getOperationNumber() {
            return this.bookinfo.$.id;
        },
        getSupplierName() {
            return _.get(this.bookinfo, 'Supplier[0]._', '');
        },
        getSupplierLocation() {
            return _.get(this.bookinfo, 'Supplier[0].$.address', '');
        },
        getSupplierConfirmationNumber() {
            return _.get(this.bookinfo, 'Supplier[0].$.confirmationNumber', '');
        },
        getVehicleName() {
            return _.get(this.bookinfo, 'Vehicle[0].Name[0]', '');

        },
        getVehicleImage() {
            return _.get(this.bookinfo, 'Vehicle[0].ImageURL[0]', '');
        },
        getSeatDoors() {
            return this.getSeats() + ' Plazas | ' + this.getDoors() + ' Puertas';
        },
        getSeats() {
            return _.get(this.bookinfo, 'Vehicle[0].$.seats', 0);
        },
        getDoors() {
            return _.get(this.bookinfo, 'Vehicle[0].$.doors', 0);

        },
        getTransmition() {
            try {
                return this.bookinfo.Vehicle[0].$.automatic == 'Manual'
                    ? 'Transmisión Manual' : 'Transmisión Automática';
            } catch (e) {
                console.error('Error al obtener la transmisión del vehiculo', e);
                return '';
            }
        },
        hasAircon() {
            try {
                return this.bookinfo.Vehicle[0].$.aircon == 'Yes';
            } catch (e) {
                console.error('Error al consultar si el vehiculo tiene aire acondicionado', e);
                return false;
            }
        },
        hasAirbag() {
            try {
                return !this.bookinfo.Vehicle[0].$.airbag == 'false';
            } catch (e) {
                console.error('Error al consultar si el vehiculo tiene bolsa de aire', e);
                return false;
            }
        },
        getFuelPolicy() {
            try {
                return this.fuelPolicy[this.bookinfo.Vehicle[0].$.fuelPolicy];
            } catch (e) {
                console.error('Error al obtener la política de combustible del vehiculo', e);
                return 0;
            }
        },
        getDays() {
            let text = this.days > 1 ? 'Días' : 'Día';
            return `${this.days} ${text}`;
        },
        getDepositisFees() {
            try {
                let depositInfo = this.bookinfo.Fees[0].DepositExcessFees[0].Deposit[0].$

                if (depositInfo.maxAmount && depositInfo.minAmount) {
                    let body = `
                          <p>
                            El conductor principal deberá presentar una tarjeta de crédito a su nombre para retirar el vehículo.
                            La tarjeta deberá disponer de fondos suficientes para cubrir el depósito/franquicia que le bloquearán hasta que finalice el alquier.
                             Deberá utilizar la misma tarjeta para pagar los impuestos locales, los extras solicitados en su reserva o cualquier otro servicio adicional que decida contratar localmente.
                        </p>
                        <p>
                            La compañía de alquier necesitará un depósito que puede variar desde ${depositInfo.minAmount} hasta ${depositInfo.maxAmount} ${depositInfo.currency} al recoger el vehículo.
                            El depósito será desbloqueado al finalizar el alquiler, siempre y cuando el vehículo sea devuelto en las condiciones acordadas.
                        </p>`
                    return body
                }
                if (depositInfo.amount) {
                    let body = `
                          <p>
                            El conductor principal deberá presentar una tarjeta de crédito a su nombre para retirar el vehículo.
                            La tarjeta deberá disponer de fondos suficientes para cubrir el depósito/franquicia que le bloquearán hasta que finalice el alquier.
                             Deberá utilizar la misma tarjeta para pagar los impuestos locales, los extras solicitados en su reserva o cualquier otro servicio adicional que decida contratar localmente.
                        </p>
                        <p>
                            La compañía de alquier necesitará un depósito de ${depositInfo.amount} ${depositInfo.currency} al recoger el vehículo.
                         El depósito será desbloqueado al finalizar el alquiler, siempre y cuando el vehículo sea devuelto en las condiciones acordadas.
                        </p>`
                    return body

                }
                return ''
            } catch (e) {
                console.error('Error al obtener la política de politica en garantia', e);
                return '';
            }
        },
        isUnlimitedMileage() {
            try {
                return this.bookinfo.Vehicle[0].$.unlimitedMileage == 'true';
            } catch (e) {
                console.error('Error al obtener el kilometraje del vehiculo', e);
                return false;
            }
        },
        getLocationTitle(type) {
            return this.bookinfo[type][0].Location[0]._;
        },
        getPickUpLocationAddress() {
            return _.get(this.bookinfo, 'PickUp[0].Location[0].$.address', '');
        },
        getPickUpSupplierPhone() {
            return _.get(this.bookinfo, 'PickUp[0].Location[0].$.telephone', '');
        },
        getDropOffLocationAddress() {
            return _.get(this.bookinfo, 'DropOff[0].Location[0].$.address', '');
        },
        getDropOffSupplierPhone() {
            return _.get(this.bookinfo, 'PickUp[0].Location[0].$.telephone', '');
        },
        getPickUpInstructions() {
            let instructions = _.get(this.bookinfo, 'Supplier[0].$.pickUpInstructions', '-');
            return instructions != '-' ? instructions : '';
        },
        getDropOffInstructions() {
            let instructions = _.get(this.bookinfo, 'Supplier[0].$.dropOffInstructions', '');
            return instructions != '-' ? instructions : '';
        },
        getCertificateNumber() {
            return _.get(this.transaction, 'redeem_certificate_number', '');
        },
        setZero(data) {
            return parseInt(data) > 9 ? data : '0' + data;
        },
        getLocation(type) {
            return this.bookinfo[type][0].Location[0].$.city + ', ' + this.bookinfo[type][0].Location[0].$.country;
        },
        getAddress(type) {
            return this.bookinfo[type][0].Location[0].$.address;
        },
        getPhone(type) {
            return this.bookinfo[type][0].Location[0].$.telephone;
        },
        getDriveName() {
            return this.bookinfo.DriverInfo[0].DriverName[0].$.firstname + ' ' + this.bookinfo.DriverInfo[0].DriverName[0].$.lastname;
        },
        getFirstName() {
            let arr = this.getDriveName().split(' ');
            return arr.length > 1 ? arr[0] : this.getDriveName();
        },
        printVoucher() {
            this.isNotPrinted = false;
            setTimeout(() => {
                window.print();
                this.isNotPrinted = true;
            }, 200);
        },
        getTerms() {
            let result = '';
            _.each(this.terms, (term) => {
                _.each(term.Term, (element) => {
                    result = result + '<strong>' + element.Caption + '</strong><p>' + element.Body + '</p>';
                })
            });
            return result.replace(/<br\s*[\/]?>/gi, '');

        },
        finishCancelation() {
            this.finishedCancel = true;
        }
    }
}
</script>
<style  lang="scss">
.terms {
    color: #5C5C5C;
    font-size: 14px;
    text-align: justify;

    p {
        margin-left: 16px;
    }
}

.box {
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 2px 8px rgba(48, 48, 48, 0.12);
    border-radius: 8px;
}

.indigo-dark {
    color: #10004F !important;
}

.indigo-normal {
    color: #1B0088 !important;
}

.indigo-bright {
    color: #4658DF !important;
}

.voucher-container {
    p {
        line-height: 28px;
        color: #5C5C5C;
    }

    .el-divider--horizontal {
        margin: 20px 0;
    }

    .col {
        padding-left: 16px;
        padding-right: 16px;
    }

    .buy-button {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        background-color: #E8114B;
        padding: 15px 50px;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
    }

}
.pdf-print {
    p{
        line-height:14px !important;
        font-size: 14px !important;
    }
    .h4 {
        font-size: 0.75rem !important;
    }
    .h5 {
        font-size: 0.62rem !important;

    }

}

</style>