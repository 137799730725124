<template>
    <el-dialog title="Información para usuarios de Brasil" :visible.sync="dialogVisible" width="50%"
        :close-on-click-modal="false">
        <div slot="title">
            <p class="latam-typography--paragraph-base h2-latam">Información para usuarios de <strong>Brasil</strong> </p>
        </div>
        <span class="latam-typography--paragraph-base" style="word-break: break-word;">El servicio de canje de arriendo de
            autos se encuentra temporalmente deshabilitado en tu país. Agradecemos su comprensión.</span>
        <span slot="footer" class="dialog-footer">
            <a href="https://www.latamairlines.com/">
                <button class="latam-button">Ver más beneficios en LATAM</button>
            </a>
        </span>
    </el-dialog>
</template>

<script>

window.togglearCancelacion = null;
import { get } from 'lodash';
export default {
    name: 'information-modal',
    props: ['user'],
    data() {
        return {
            dialogVisible: false,
        }
    },
    created() {
        setTimeout(() => {
            let memberInfo = get(this.user, 'member_info', null)
            let billing = get(JSON.parse(memberInfo), 'Person.billing', false) == 'JJ'
            this.dialogVisible = billing;
        }, 1000)
    },
    mounted() {
    },
    methods: {
        closeModalEnd() {
            this.dialogVisible = false
        }
    }
}
</script>

<style >
.h2-latam {
    font-size: 22px !important;
    color: #10004F !important;
}

.el-dialog__header {
    padding: 2.2rem 2rem 0 2.2rem
}

.el-dialog__body {
    padding: 0 2rem 2.2rem 2rem;
}

.el-dialog__footer {
    padding: 0 2.2rem 2rem;
}

.latam-typography--paragraph-base {
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.013rem;
    font-weight: 400;
    color: rgb(92, 92, 92);
}

.el-dialog__footer {
    display: flex;
    justify-content: center;
}

.latam-button {
    background: none rgb(232, 17, 75);
    border-radius: 0.5rem;

    font-weight: 600;
    line-height: 1;
    text-transform: none;
    transition: none 0s ease 0s;
    border: 0.0625rem solid rgb(232, 17, 75);
    color: rgb(255, 255, 255);
    font-size: 1.125rem;
    height: 3.5rem;
    padding: 1rem 2rem;
}

.cancel-button {
    color: #E8114B;
}
</style>

