import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
      milesBalance: 'nothing',
      rentalTerms: [],
      match: {}
    },
    getters: {
        getMilesBalance(state) {
            return state.milesBalance;
        },
        getRentalTerms(state){
            return state.rentalTerms;
        },
        getMatch(state){
            return state.match;
        }
    },
    mutations: {
        setMilesBalance: function (state, miles) {
            state.milesBalance = miles;
        },
        setRentalTerms: function (state, terms){
            state.rentalTerms = terms;
        },
        setMatch(state, match){
            state.match = match;
        },
        analyticDetail(state, car_data){
            dataLayer.push({
                "event":"ECProductDetailEvent",
                 "ecommerce": {
                   "currencyCode":"USD",  //usar siempre USD
                   "detail": {
                     "products": [
                        {
                            "id": car_data.id,
                            "name": car_data.name,  // nombre del auto
                            "price": car_data.price,  //precio en millas por 1 día de arriendo
                            "brand": car_data.provider,  
                            "category": car_data.ubitcation, // Usar {Pais}/{Ciudad}/{Ubicación} de pick up
                        }
                    ]
                  }
                }
              });
        }
    },
  });