export default {
    data() {
        return {
            width: 0,
            height: 0
        }
    },
    prepare() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed(){
        window.removeEventListener('resize', this.handleResize);
    },
    handleResize(windowObj) {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },
    isLandscape(){
        return (this.window.width / this.window.height) < 1;
    }
}