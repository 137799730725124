<template>
    <div class="booking-extras-page-component">
        <div class="row" v-if="!isLandscape()">
            <div class="col-12">
                <el-steps :active="step">
                    <el-step title="Paso 1" description="Seleccionar Extras"></el-step>
                    <el-step title="Paso 2" description="Información Personal"></el-step>
                    <el-step title="Paso 3" description="Confirmación"></el-step>
                </el-steps>
            </div>
        </div>

        <div class = "row" v-if="step != 3">
            <div class = "col-md-12">
                <div class="warning-alert insufficient-miles little-chicken">
                    <span style="color:#ED1650;font-weight:bold;">
                        Los canjes son cancelables 48h previo a la hora de recogida del auto.<br>
                        Los canjes no permiten cambios. Sólo válido para el titular de la cuenta LATAM Pass y no transferible.
                    </span>
                </div>
            </div>
        </div>

        <div class = "row" v-if="!loadingbooking">
            <div class = "col-sm-4" style = "margin-top:10px;margin-bottom:10px;">
                <a @click="goToResult()" class = "step_previous">Volver a los resultados</a>
            </div>
            <div class = "col-sm-8" v-show="step === 1">
                
                <div class="minicard-component modify_minicart" :class="{ 'active' : tabTerms }" style ="float:right;" v-on:click="tabTerms = true">
                    {{ !isLandscape() ? 'Información importante' : 'Info Importante' }}
                </div>
                <div class="minicard-component modify_minicart" :class="{ 'active' : !tabTerms }" style ="float:right;" v-on:click="tabTerms = false">
                    {{ !isLandscape() ? 'Información sobre el vehículo' : 'Info sobre vehículo' }}
                </div>
                
            </div>
            <div class = "col-sm-12" style = "margin-top:-21px;padding-right:80px;z-index:-999;">
                <hr class = "tabshr">
            </div>
        </div>

        <div class="step" v-show="step === 1" style="margin-top:-20px;">
            <div v-if="!tabTerms">

                <div class="row" v-loading="loadingMatch">
                    <div class="col-12">
                        <card v-if="match && !isLandscape()" :match="match" :days="search.days" :show-book-button="false"></card>
                        <card-mobile v-if="match && isLandscape()" :match="match" :days="search.days" :show-book-button="false"></card-mobile>
                    </div>
                </div>
                <br>
                <div class="row" v-loading="loadingExtras" v-if="false">
                    <div class="col-12">
                        <div class="card">
                            <h5 class="card-header idnumber"><strong>Extras</strong></h5>
                            <div class="card-body">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Extra</th>
                                        <th>Precio</th>
                                        <th>Cantidad</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="extra in extrasData">
                                        <td>{{ extra.name }}</td>
                                        <td>{{ numeral(extra.miles).format('0,0.[00]') }} millas</td>
                                        <td>
                                            <el-input-number v-model="extra.value" :min="0" :max="extra.available" :size="!isMinimalScreen() ? '' : 'mini'"></el-input-number>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row separator" v-loading="loadingExtras" v-if="existCoverPolicy()">
                    <div class="col-12">
                        <div class="card">
                            <h5 class="card-header idnumber"><strong>Añadir la Cobertura Premium por Colisión</strong></h5>
                            <div class="card-body">
                                
                                <div class = "row">
                                    <div class = "col-12">
                                        <div style="float:left; width:70%;">
                                            <p>¡Añada la Cobertura premium por colisión a su reserva! Podrá asegurar su vehículo contra pérdidas o daños cubiertos. 
                                                Haga clic aquí para más información </p>
                                        </div>

                                        <div style="float:right; text-align:right;width:25%;">
                                            <p>
                                                Por tan solo <br>
                                                <span class = "finaltitle"><h5><strong>{{getCoverPolicy().miles}} millas</strong></h5></span> 
                                            </p>
                                        </div>

                                    </div>
                                    <div class = "col-6">
                                        <a  class = "cover-button" :href="getCoverPolicy().PreBookingPolicyWordingURI" target="_blank">Ver Datos de la Cobertura</a>
                                    </div>
                                </div>
                                <div class = "row">
                                    <div class = "col-12" style="margin-top:20px;">
                                        <p class="small" style="margin-bottom:0px;">
                                            Booking.com Transport Limited [nº de registro de empresa FCA Nº 777648] es representante autorizado de Cover Genius Limited, autorizada y registrada por la Autoridad de Conducta Financiera con Nº de registro de empresa 750711. Traveljigsaw Insurance Limited está establecida en Malta y está autorizada por la Malta Financial Services Authority para operar en el negocio de la aseguración en el marco legal de la Insurance Business Act de 1998. Número de registro: C82173. Sede registrada en: Development House, St Anne Street, Floriana, FRN 9010, Malta.
                                        </p>
                                    </div>
                                </div>

                                <!--
                                <div class = "row">
                                    <div class = "col-12" v-html="getCoverPolicy().inclusions">
                                        
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tabTerms">
                <div class="row" v-loading="loadingExtras" style="margin-top:20px;">
                    <div class="col-12">
                        <div class="card">
                            <h5 class="card-header idnumber"><strong>Información importante</strong></h5>
                            <div class="card-body">
                                <div v-for="term in getTerms()">
                                    <div class = "row">
                                        <div class = "col-md-4">
                                            <strong>{{term.Caption}}</strong>
                                        </div>
                                        <div class = "col-md-8" v-html="term.Body">
                                        </div>
                                        <div class = "col-md-12" style="padding:0px;">
                                            <hr style="border-style:dotted;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-sm-12 text-right" v-if="(match != null)">
                    
                    <button type="submit" class="cover-book-button" @click="book(false)" >
                        <strong>Reservar</strong><p style="margin-bottom:0px;">
                        <span class = "childbutton">
                            Sin la cobertura parcial
                        </span>
                        </p>
                    </button>
                    <button type="submit" class="buy-button" @click="book(true)">
                        <strong>Reservar</strong><p style="margin-bottom:0px;">
                        <span class = "childbutton">
                            Con la cobertura parcial
                        </span>
                        </p></button> 
                    
                   <!-- <button type="submit" class="buy-button" @click="book(false)" :disabled="(match == null)">Siguiente</button> -->
                </div>
            </div>
        </div>

        <div class="step" v-show="step === 2">
            <div class = "row">
                <div class = "col-md-12">
                    <div class="card">
                        <h5 class="card-header">Información Personal</h5>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="firstname">Nombre</label>
                                <input id="firstname" type="text" class="form-control" v-model="personalData.firstname" :class="{'is-invalid': errors['firstname']}" :disabled="existLatamName">
                                 <div class="invalid-feedback" v-for="e in errors['firstname']">
                                    {{e}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="lastname">Apellido</label>
                                <input id="lastname" type="text" class="form-control" v-model="personalData.lastname" :class="{'is-invalid': errors['lastname']}" :disabled="existLatamLastName">
                                <div class="invalid-feedback" v-for="e in errors['lastname']">
                                    {{e}}
                                </div>
                            </div>
                            <div class="form-group">
                                <div style = "float:left">Correo Electrónico</div>
                                <div style = "float:right" class = "little-chicken"><span style="color:red;">*</span>Ingresar correo electrónico registrado en la cuenta socio LATAM Pass</div>
                                                              
                                <input id="email" type="text" class="form-control" v-model="personalData.email" :class="{'is-invalid': errors['email']}" :disabled="existLatamEmail">
                                <div class="invalid-feedback" v-for="e in errors['email']">
                                    {{e}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="telephone">Teléfono</label>
                                <input id="telephone" type="text" class="form-control" v-model="personalData.telephone" :class="{'is-invalid': errors['telephone']}">
                                <div class="invalid-feedback" v-for="e in errors['telephone']">
                                    {{e}}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="flightnumber">Número de vuelo <span class ="little-chicken">(Recomendado)</span></label>
                                <input id="flightnumber" type="text" class="form-control" v-model="personalData.flightNumber" :class="{'is-invalid': errors['flightNumber']}">
                                <div class="invalid-feedback" v-for="e in errors['flightNumber']">
                                    {{e}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class = "row separator">
                <div class = "col-md-12">
                    <div class="card">
                        <h5 class="card-header idnumber"><strong>Tus millas Pass</strong></h5>
                        <div class="card-body">
                            <div class = "row summary">
                                <div class = "col-md-12">
                                    <div class = "float-left">TOTAL ACTUAL</div>
                                    <div class = "float-right positive_miles"><h5><strong>{{ numeral(getMiles()).format('0,0.[00]') }}</strong></h5></div>
                                </div>
                            </div>
                            <div class = "row midle">
                                <div class = "col-md-12">
                                    <div class = "float-left">COSTO ARRIENDO</div>
                                    <div class = "float-right positive_miles"><h5><strong>{{ numeral(getTotalPrice()).format('0,0.[00]') }}</strong></h5></div>
                                </div>
                            </div>
                        </div>
                        <div class = "card-footer total">
                            <div class = "row">
                                <div class = "col-md-12">
                                    <div class = "float-left">TUS MILLAS LUEGO DE LA TRANSACCIÓN </div>
                                    <div class = "float-right" :class="{ positive_miles : isPositive() , negative_miles : !isPositive() }"><h5><strong>{{ numeral(getDifference()).format('0,0.[00]') }}</strong></h5></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class = "row separator" v-if="!isPositive()">
                <div class = "col-md-12">
                    <div class="insufficient-miles">
                        <div class="title">Uso de millas Latam Pass</div>
                        <ul class="sidebar-list">
                            <li>No tienes las millas suficientes para canjear pasajes en esta ruta, actualmente tienes {{ numeral(getMiles()).format('0,0.[00]') }} millas. en tu cuenta LATAM Pass y te faltan {{ numeral(Math.abs(getDifference())).format('0,0.[00]') }} Millas.Te invitamos a comprar las Millas que te faltan para continuar con tu canje. Conoce las condiciones de la compra de Millas.</li>
                        </ul>

                        <div class = "row">
                            <div class = "col-md-12">
                                <div class = "float-right">
                                    <button type="button" class="buy-miles" @click="buymiles()"><i class="material-icons">exit_to_app</i> Ir a compra de millas</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row separator">
                <div class="col-sm-12 text-right">
                    <a @click="step = 1" class = "step_previous">Volver al paso anterior</a>
                    <button type="submit" class="buy-button" @click="booking()" :disabled="!isPositive()">Canjea tus millas</button>
                </div>
            </div>
        </div>

        <div class="step" v-show="step === 3">
            <div class="row" v-loading="loadingbooking">
                <div class = "col-md-12" v-if="bookerror">
                    <div class="insufficient-miles">
                        <div class="title">Ha ocurrido un problema</div>
                        <ul class="sidebar-list">
                            <li>{{errormsg}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12" v-if="!bookerror">
                    <div class="card">
                        <div class="card-body" v-if="idbooking != null">
                            <div class = "row">
                                <div class = "col-md-12">
                                    <h3 class = "finaltitle">Gracias por Confiar en Nosotros</h3>
                                    <p>Puede consultar la información relacionada con su reserva descargando el siguiente váucher.</p>
                                </div>
                            </div>
                            <div class = "row">
                                <div class = "col-md-12">
                                    <div class = "finalsquare">
                                        <div class = "bookingid">
                                            <h5>NÚMERO DE REFERENCIA DE SU RESERVA:</h5>
                                            <p>
                                                <h4 class = 'idnumber'>{{idbooking}}</h4>
                                            </p>

                                            <p>
                                                <el-button @click="downloadVoucher()" type="danger">Descargar Voucher<i class="ml-2 el-icon-download el-icon-right"></i></el-button>
                                            </p>
                                            <p>
                                                <a target="_blank" href="/my-reservations">Ir a mis reservas</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right">
                    <button type="button" class="buy-button final-button" @click="ending()" :disabled="loadingbooking">Finalizar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .finaltitle{
        color: #2D34CE;
        font-weight: 800;
    }

    .finalsquare {	
        box-sizing: border-box;	
        border: 1px solid #CCCCCC;	
        border-radius: 4px;	
        background-color: #F5F5F5;
    }

    .bookingid {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

    }

    
    .buy-button {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        background-color: #1B0088;
        padding: 15px 50px;
        border: 0;
        border-radius: 4px;
        padding: 5px 5px 5px 5px;
        cursor: pointer;
    }

    .cover-button {
        color : #1B0088;
        font-size: 18px;
        padding: 15px 50px;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: underline #1B0088;
    }

    .buy-button:disabled {
        background-color: #CCCCCC;
        color: #FFFFFF;
    }

    .cover-book-button {
        color : #1B0088;
        font-size: 18px;
        padding: 15px 50px;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.125);
        padding: 5px 10px 5px 10px;
    }

.buy-miles {
      @extend .buy-button;
      color : #1B0088;
      background-color: #ffffff;
    }

    .summary {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px #dededd dotted;
    }

    .midle {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .total {
        border-top: 1px #dededd dotted;
        background-color: #F5F5F5;
    }

    .separator {
        margin-top: 20px;
    }

    .negative_miles {
        color: #ED1650;
    }

    .positive_miles {
        color: #00B5AD;
    }

    .step_previous {
        margin-right: 30px;
        color: #2D34CE !important;
        cursor: pointer;
    }

    .tabshr {
        height: 1px;
        background-color: #E6E6E6;
    }

    .modify_minicart {
        border-radius: 4px 4px 0px 0px;
        -webkit-box-shadow: 0 0 2px 0 #D3D3D3, inset 0px -2px 0px #E6E6E6;
        box-shadow: 0 0 2px 0 #D3D3D3, inset 0px -2px 0px #E6E6E6;
        font-size:small;
    }

    .modify_minicart.active {
        background-color: #FFFFFF;
        -webkit-box-shadow: 0 0 2px 0 #D3D3D3, inset 0px -2px 0px #2D34CE;
        box-shadow: 0 0 2px 0 #D3D3D3, inset 0px -2px 0px #2D34CE;
    }

    .little-chicken {
        font-size: small;
    }

    .cover-modal{
        height:400px;
        padding:30px;
        overflow-y:auto;
    }

    .cover-title {
        padding-left:30px;
        padding-top: 30px;
    }

    .cover-footer {
        padding-left: 30px;
        padding-bottom: 30px;
        padding-right: 30px;
        padding-top: 15px;
        text-align: right;
    }

    .final-button{
        margin-top:10px;
        margin-bottom:10px;
    }

    .warning-alert {
        padding: 10px;
        margin-top: 15px;
        margin-bottom: 15px
    }
</style>

<script>
    import Card from '@/components/Card';
    import CardMobile from '@/components/CardMobile';
    import _ from 'lodash';
    import axios from 'axios';
    import qs from 'qs';
    import resize from '@/../resize.js';

    export default {
        props: {
            search: {
                type: Object,
                required: true
            },
            protectionProduct: {
                type: String,
                required: true
            },
            user: {
                type: Object,
                required: true
            },
            searchUrl: {
                type: String,
                required: true
            },                
        },
        data() {
            return {
                loadingMatch: false,
                loadingExtras: false,
                loadingTerm: false,
                loadingbooking: false,
                match: null,
                extras: null,
                terms: null,
                include : null,
                extrasData: null,
                personalData: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    telephone: '',
                },
                idbooking: null,
                supplierReservationID: null,
                transaction: null,
                step: 1,
                bookerror : false,
                errormsg : '',
                errors : {},
                tabTerms: false,
                window: resize.data(),
                isPolicy: false,
                existLatamEmail: false,
                existLatamLastName: true,
                existLatamName: true,
                voucherUrl : ''
            }
            /*
            let extrasData = prepareExtrasData(this.extras);
            return {
                form: {
                    data: {
                        extras: extrasData.filter(e => e.product !== this.protectionProduct),
                        protection: extrasData.find(e => e.product === this.protectionProduct)
                    }
                }
            };
            */
        },
        watch:{
            extras: function(){
                this.extrasData =  this.extras.ExtrasList.map(extra => {
                    let defaultOptIn = _.get(extra, '$.defaultOptIn');
                    let insurance = _.get(extra, '$.insurance');
                    let returned = {
                        defaultOptIn: defaultOptIn,
                        insurance: insurance,
                        reference : _.get(extra, 'Extra[0].$.reference'),
                        available: parseInt(_.get(extra, 'Extra[0].$.available')),
                        id: _.get(extra, 'Extra[0].$.id'),
                        product: _.get(extra, 'Extra[0].$.product'),
                        name: _.get(extra, 'Extra[0].Name[0]'),
                        comments: _.get(extra, 'Extra[0].Comments[0]'),
                        price: _.get(extra, 'Price[0]._'),
                        priceDetails: _.get(extra, 'Price[0].$'),
                        miles: _.get(extra, 'Price[0].miles.pricePerRental'),
                        value: defaultOptIn ? 0 : 1
                    };

                    if (insurance == 'true'){
                        returned.PreBookingPolicyWordingURI = _.get(extra, 'PreBookingURIs[0].PreBookingPolicyWordingURI[0]');
                    } 

                    return returned;
                });
            }
        },
        created() {
            this.prepare();
        },
        computed:{
            searchRedirect: function(){
                return '';
            }
        },
        components: {
            Card,
            CardMobile
        },
        mounted: function(){
            this.loadingMatch = true;
            axios.get('api/car-search? ' + qs.stringify(this.search))
                .then(response => {
                    this.loadingMatch = false;
                    this.match = response.data.find(v => _.get(v, 'Vehicle[0].$.id') === this.search.vehicleId);
                    this.$store.commit('setMatch', this.match);
                });

            this.loadingExtras = true;
            axios.get('api/car-extras? ' + qs.stringify(this.search))
                .then(response => {
                    this.loadingExtras = false;
                    this.extras = response.data;
                });

            this.loadingTerm = true;
            axios.get('api/car-terms?' + qs.stringify(this.search))
                .then(response => {
                    this.loadingTerm = false;
                    this.terms = response.data;
                    this.$store.commit('setRentalTerms', this.terms);
                });

            
            this.personalData.firstname = this.getUserNames('firstName') + (this.getUserNames('middleName') === null ? '' : ' ' + this.getUserNames('middleName'));
            this.personalData.lastname = this.getUserNames('lastName') + (this.getUserNames('secondLastName') === null ? '' : ' ' + this.getUserNames('secondLastName'));
            this.personalData.email = this.getEmail();
        },
        destroyed: resize.prepare,
        methods: {
            numeral: numeral,
            prepare: resize.prepare,
            handleResize: resize.handleResize,
            isLandscape: resize.isLandscape,
            getUserNames(param){
                let user_info = JSON.parse(this.user.member_info);
                return typeof(user_info.Person.Name[param]) != 'undefined' ? user_info.Person.Name[param] : '';
              
            },
            getEmail(){
                this.existLatamEmail = true;
                let user_info = JSON.parse(this.user.member_info);
                try{
                    return user_info.Person.ContactInformation.Emails.Email[0].email;
                }catch(e){
                    console.error('No se pudo obtener el email del usuario');
                    this.existLatamEmail = false;
                }
                return '';
            },
            downloadVoucher(){
                window.open(this.voucherUrl, '_blank');
            },
            booking(){
                let policyPrice = 0;
                if (this.isPolicy){
                    policyPrice = this.getCoverPolicy().miles;
                }
 
                this.step = 3;
                this.loadingbooking = true;
                axios.post('api/make-booking', {
                    pickUp : this.search.pickUp,
                    dropOff: this.search.dropOff,
                    personalData : this.personalData,
                    vehicleId : this.search.vehicleId,
                    baseCurrency : this.match.cardAttributes.baseCurrency,
                    basePrice : this.match.cardAttributes.basePrice,
                    totalmiles : this.getTotalPrice(),
                    extrasData : this.getExtras(),
                    isPolicy : this.isPolicy,
                    policyPrice : policyPrice
                }).then(response => {
                    this.loadingbooking = false;
                    if (typeof response.data.error != 'undefined'){
                        this.catchError(response.data);
                        return;
                    }

                    this.transaction = response.data.result;
                    this.voucherUrl = response.data.url;
                    this.idbooking = this.transaction.rentalcars_id;
                    this.supplierReservationID = _.get(this.transaction, '$.defaultOptIn');
                    this.$store.commit('setMilesBalance', this.transaction.user_balance);
                    
                }).catch(error => {
                    console.error(error);
                });
            },
            bookingError(){

            },
            ending(){
                window.location.href = '/';
            },
            getExtras(){
                let extras = [];
                this.extrasData.forEach(element => {
                    if (element.insurance == 'true' && this.isPolicy){
                        extras.push(element);
                        return;
                    }

                    if (element.value > 0){
                        extras.push(element);
                    }
                });


                return extras;
            },
            getMiles(){
                let user_info = JSON.parse(this.user.member_info);
                return typeof(user_info.balance) != 'undefined' ? user_info.balance : 0;
            },
            getTotalPrice(){
                
                let extramiles = 0;
                _.each(this.extrasData, element => {
                    extramiles = extramiles + (element.miles * element.value);
                });

                let coverPrice = 0;
                if (this.isPolicy){
                    coverPrice = this.getCoverPolicy().miles;
                }
                
                if (this.match == null){
                    return 0;
                }
                let matchprice = this.match.cardAttributes.mileTotalPrice;
                return extramiles + matchprice + coverPrice;
                
            },
             getDifference(){
                return this.getMiles() - this.getTotalPrice();
            },
            isPositive(){
                return this.getDifference() >= 0;
            },
            buymiles(){
                window.location.href = buyMilesUrl;
            },
            catchError(data){
                if (typeof data.messages == 'undefined'){
                    this.bookerror = true;
                    this.errormsg = data.error;
                    return;
                }

                _.each(data.messages, (elem, key) => {
                    this.errors[elem.field] = [elem.message];
                });

                this.step = 2;
            },
            goToResult(){
                window.location.href = this.searchUrl;
            },
            getTerms(){
                let result = [];
                _.each(this.terms, element => {
                    if (element.$.type == "PaymentInfo"){
                        return;
                    }

                    _.each(element.Term, term => {
                        result.push({
                            Body:term.Body + ' ',
                            Caption: term.Caption + ' '
                        });
                    });

                })

                return result;
            },
            isMinimalScreen(){
                return (this.window.width < 420);
            },
            getCoverPolicy(){
               // console.log('Inclusions: ', this.extras.CoverPolicy[0].CoverPolicy[0].$.inclusions.replace('<br><br />', ''));
                for(let i = 0; i < this.extrasData.length; i++){
                    if (this.extrasData[i].insurance == 'true'){
                        return this.extrasData[i];
                    }
                }

                return {};
            },
            existCoverPolicy(){

                for(let i = 0; i < this.extrasData.length; i++){
                    if (this.extrasData[i].insurance == 'true'){
                        return true;
                    }
                }

                return false;
            },
            showCoverPolicy(){
                this.$modal.show('cover-policy');
            },
            hideCoverPolicy () {
                this.$modal.hide('cover-policy');
            },
            getInclusions(){
                return this.killList(this.getCoverPolicy().inclusions, [
                    '<br><br />', '<br><br />', '<br><br />', '<br />','<br />','<br />','<br />','<br />','<br />','<br />', '<br />','<br />','<br />','<br />','<br />','<br />','<br />'
                ], 0);
            },
            killList(str, list, index){
                return typeof(list[index]) != 'undefined' ? this.killList(str.replace(list[index], ''), list, index + 1) : str;
            },
            book(isPolicy){
                this.step = 2;
                this.isPolicy = isPolicy;
            }
        }
    };
</script>
