<template>
    <div class = "row">

        <div class = "col-md-12 separation" v-if="!isLandscape()">
        </div>

        <div class = "col-md-12 separation" v-if="!isLandscape() && existRatings()">
            <div class="card">
                <div class="card-body">
                    
                    <div class="image-container align-self-end center-ratings">
                        <img :src="match.Supplier[0].$.small_logo" class="mx-auto ">
                    </div>
                    <div class="general-info center-ratings">
                        <i class="material-icons rat_icons">stars</i> <strong>Valoración</strong>
                    </div>
                    <div class="general-info center-ratings">
                        <span class = "valoration"><h4>{{ ratings('Average') }}/10</h4></span>
                    </div>
                    <div class="general-info center-ratings">
                        {{ ratings('AverageText') }}
                    </div>
                    

                    <div class="general-info">
                    </div>
                </div>
            </div>
        </div>
        <div class = "col-md-12 separation" v-if="!isLandscape() && existRatings()">
            <div class="card ratings">
            
                <div class="general-info smalltext" v-for = "(map, key) in maprat" :key="key">
                    <i class="material-icons rat_icons">{{map.icon}}</i><strong> {{map.label}}</strong> {{ratings(key)}}
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .valoration {
        color: #00B5AD;
    }

    .smalltext {
        font-size: small;
    }

    .center-ratings {
        text-align:center;
        font-size: small;
        margin-bottom:10px;
    }

    .rat_icons {
        position:relative;
        top: 6px;
    }

    .separation {
        margin-top:20px;
    }

    .ratings {
        padding:10px;
    }
</style>

<script>

import resize from '@/../resize.js';
import Card from '@/components/Card';

export default {
    data(){
        return {
            ratingTypes: {
                'Cleanliness' : {
                    'icon' : 'drive_eta',
                    'label' : 'Inclinación'
                },
                'CollecTime' : {
                    'icon' : 'access_time',
                    'label' : 'Tiempo Espera Recogida'
                },
                'Condition' : {
                    'icon' : 'drive_eta',
                    'label' : 'Estado del Vehiculo'
                },
                'DropOffTime': {
                    'icon' : 'av_timer',
                    'label': 'Tiempo Espera Devolución'
                },
                'Efficiency' : {
                    'icon'  : 'check_circle_outline',
                    'label' : 'Eficiencia'
                },
                'Locating' : {
                    'icon'  : 'location_on',
                    'label' : 'Localización del Vehiculo'
                },
                'ValForMoney' : {
                    'icon'  : 'monetization_on',
                    'label' : 'Precio'
                }
            },
            window: resize.data()
        }
    },
    components: {
        Card
    },
    computed: {
        match (){ 
            return this.$store.state.match;
        },
        maprat () {
            let result = {};

            if (!this.existRatings()){
                return {};
            }

            _.each(this.match.Ratings[0], (element, key) => {
                if (typeof(this.ratingTypes[key]) != 'undefined'){
                    result[key] = this.ratingTypes[key];
                }
            });

            return result;
        }
    },
    created() {
        this.prepare();
    },
    destroyed: resize.prepare,
    methods: {
        numeral: numeral,
        prepare: resize.prepare,
        handleResize: resize.handleResize,
        isLandscape: resize.isLandscape,
        ratings(key){
            if (!this.existRatings()){
                return '';
            }
            return this.match.Ratings[0][key][0];
        },
        existRatings(){
            return typeof(this.match.Ratings) != 'undefined';
        }

    }
}
</script>
