<template>
    <div>
        <div class = "sombra"></div>
        <div class = "container">
            <div class = "row primary-row">
                <div class = "col-md-12 navbar-pos">
                    <div>
                        <h5 class = "idnumber"><strong>Mis reservas</strong></h5>
                    </div>
                    <div>
                        <a href="javascript: history.go(-1)" class = "idnumber">← Volver atrás</a>
                    </div>
                </div>
            </div>
            <div class = "row">
                <div class = "col-md-12">
                    <div class = "card">
                        <div class = "card-body">
                            <div class = "container">
                                <div class = "row">
                                    <div class = "col-md-12">
                                        <span class = "idnumber"><strong>Reservas Activas</strong></span>
                                    </div>
                                </div>
                                <div class = "row">
                                    <div class = "col-md-6 card-p" v-for="op in activeTransactions" :key="op.id">
                                        <div class = "card">
                                            <div class = "card-body">
                                                <div class = "row">
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box ">NÚMERO DE REFERENCIA</span> <br>
                                                        <span class = "idnumber"><strong>{{op.rentalcars_id}}</strong></span>
                                                    </div>
                                                </div>
                                                <div class = "row">
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">REALIZADA EL</span> <br>
                                                        {{getCreated(op)}}
                                                    </div>
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">FECHA RECOGIDA</span> <br>
                                                        {{op.init_date}}
                                                    </div>
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">FECHA DEVOLUCIÓN</span> <br>
                                                        {{op.end_date}}
                                                    </div>
                                                </div>
                                                <div class = "row">
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">COCHE</span> <br>
                                                        {{op.vehicle}}
                                                    </div>
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">DÍAS TOTALES</span> <br>
                                                        {{op.difference}}
                                                    </div>
                                                    <div class = "col-md-4">
                                                        <span class = "sb-box">COSTO DE ARRIENDO</span> <br>
                                                        {{numeral(op.redeem_amt_discount).format('0,0.[00]')}}
                                                    </div>
                                                </div>
                                                <a :href="voucherUrl(op)" target="_blank" class = "btn detail-button center-button ">
                                                    <i class = "material-icons fixed">remove_red_eye</i> Ver voucher
                                                </a>

                                                
                                                <a href="#" v-on:click="showModalCancel(op)" class="btn cancel-button center-cancel" v-if="getIfCancellable(op)"> <i class="material-icons" >cancel</i> Cancelar Reserva</a>
                                        
                                            </div>
                                        </div>
                                        
                                    
                                    </div>

                                    <div class = "col-md-12" v-if="activeTransactions.length == 0">No tienes reservas activas</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class = "row" style = "margin-top:20px; margin-bottom:20px;">
                <div class = "col-md-12">
                    <div class = "card">
                        <div class = "card-body">
                            <div class = "container">
                                <div class = "row">
                                    <div class = "col-md-12">
                                        <span class = "idnumber"><strong>Historial de reservas</strong></span>
                                    </div>
                                </div>
                                <div class = "row">
                                    <div class = "col-md-12">
                                        <table class = "table">
                                            <thead>
                                                <tr class = "table-head sb-box">
                                                    <th>REALIZADA EL</th>
                                                    <th>NÚMERO DE REFERENCIA</th>
                                                    <th>COCHE</th>
                                                    <th>DÍAS TOTALES DE LA RESERVA</th>
                                                    <th>COSTO DE ARRIENDO</th>
                                                    <th>ESTADO</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="op in paginatedTransactions" :key="op.id" class = "table-row">
                                                    <td>{{getCreated(op)}}</td>
                                                    <td><span class = "idnumber"><strong>{{op.rentalcars_id}}</strong></span></td>
                                                    <td>{{op.vehicle}}</td>
                                                    <td>{{op.difference}}</td>
                                                    <td>{{numeral(op.redeem_amt_discount).format('0,0.[00]')}}</td>
                                                    <td>
                                                        <div class="canceled-reservation" v-if="op.canceled_at">Cancelada</div>
                                                        <div v-else :class="checkIfActive(op) ? 'active-reservation' : 'expired-reservation'">
                                                            {{checkIfActive(op) ? 'Activa' : 'Utilizada'}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a :href="voucherUrl(op)" target="_blank" class = "idnumber">
                                                            Ver detalle
                                                        </a>

                                                        <a href="#" v-on:click="showModalCancel(op)" class="cancel-button" style="margin-left: 20px;font-size: 12px;" v-if="getIfCancellable(op) && isNotCanceled(op)"> <i class="material-icons" >cancel</i> Cancelar</a>

                                                    </td>
                                                </tr>

                                                <tr v-if="transactions.length == 0">
                                                    <td colspan="6"> No posee reservas en su historial</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <el-pagination
                                            v-if="transactions.length > pagination.pageSize"
                                            background
                                            layout="prev, pager, next"
                                            class="text-center"
                                            :total="transactions.length"
                                            :page-size="pagination.pageSize"
                                            :current-page="pagination.currentPage"
                                            @current-change="handlePageChange"
                                       />

                                        <cancelation-modal :booking="selectedBooking" @view-modal-cancel="bindCancelAction" ref="cancelation" @finish-cancelation="finishCancelation"></cancelation-modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import CancelationModal from '@/components/CancelationModal';

export default {
    name : 'my-reservations',
    props : ['transactions', 'user'],
    components: {
        CancelationModal
    },
    data(){
        return {
            operations : _.cloneDeep(this.transactions),
            selectedBooking : null,
            checkUserWantToCancel : false,
            cancelLoading : false,
            cancelError : false,
            cancelEnd : false,
            widthModal: '40%',
            cancelAction : null,
            pagination : {
                pageSize : 10,
                currentPage : 1
            }
        }
    },
    created(){
        window.togglearCancelacion = () => {
            this.checkUserWantToCancel = !this.checkUserWantToCancel;
        };
    },
    computed : {
        activeTransactions(){
            
            return _.filter(this.operations, item => {
                return this.checkIfActive(item) && this.isNotCanceled(item);
            });
            
        },
        paginatedTransactions(){

            const pointer = this.pagination.pageSize * (this.pagination.currentPage - 1);

            let result = [];

            for(let i = pointer; i < (pointer + this.pagination.pageSize); i++){
                if (!this.operations[i]){
                    break;
                }

                result.push(this.operations[i]);
            }

            return result;
        }
    },
    methods : {
        bindCancelAction(action){
            this.cancelAction = action;
        },
        getCreated(op){
            let create_arr = op.created_at.split(' ');
            return create_arr[0];
        },
        voucherUrl(op){
            return '/booking-details?booking=' + op.rentalcars_id + '&customer=' + this.user.rut;
        },
        showModalCancel(op){
            this.selectedBooking = op;
            this.cancelAction();
        },
        getIfCancellable(op){
            if (op.canceled_at != null){
                return false;
            }

            let book = JSON.parse(op.booking_info);
            let init = op.init_date.split('/');
            let pick = init[2] + '-' + init[1] + '-' + init[0] + ' ' + book.PickUp[0].Date[0].$.hour + ':' + book.PickUp[0].Date[0].$.minute;

            let difference = moment(pick, 'YYYY-MM-DD HH:mm').diff(moment.utc(), 'minutes');

            return difference >= (48 * 60);

        },
        checkIfActive(op){
            let ending = moment(op.end_date, 'DD/MM/YYYY');

            let difference = ending.diff(moment(), 'days');
            
            return (difference >= 0);
        },
        isNotCanceled(op){
            return op.canceled_at === null;
        },
        finishCancelation(book){
            _.each(this.operations, (op, index) => {
                if (op.id == book.id){
                    this.operations[index].canceled_at = 'canceled';
                }
            });
        },
        handlePageChange(page){
            this.pagination.currentPage = page;
        },
        numeral: numeral
    }
}
</script>

<style>

    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2D34CE !important;
    }

</style>

<style scoped>

    .primary-row {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .sb-box {
        font-size: 0.7em;
        color: #333333;
        font-weight: bold;
    }

    .card-p {
        padding: 10px;
        margin-bottom: 20px;
    }

    .center-button {
        margin-bottom: -60px;
        margin-left: 10%;
        width: 40%;
        padding: 10px;
        z-index:10;
    }

    .center-cancel {
        margin-bottom: -60px;
        width: 40%;
        display: inline-block;
        padding: 14px;
        z-index:10;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        background-color: #fff;
        font-weight: 400;
    }

    .fixed {
        position: relative;
        top:8px;
        left: 0px;
    }

    .table-head {
        border: 1px solid #dee2e6;
        background-color:#F7F7F7;
        color: #333333;
    }
    
    .table-row {
        border: 1px solid #dee2e6;
    }

    .sombra {
        height: 30px;
        background: rgba(237,237,237,1);
        background: -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237,237,237,1)), color-stop(53%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
        background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
        background: -o-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
        background: -ms-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=0 );
    }

    .cancel-button {
        color: #E8114B;
    }

    .cancel-button > i {
        font-size: 12px;
        vertical-align: middle;
    }

    .center {
         text-align: center;
    }

    


</style>
